<template>
    <div class="page">
        <div class="header">
            <div style="float:right">
                <el-button size="mini" @click="todo" icon="el-icon-download" type="defalut">导出</el-button>
                <el-button size="mini" @click="todo" icon="el-icon-refresh">刷新</el-button>
            </div>
            <el-page-header @back="$router.go(-1)" content="项目登记" />
        </div>
        <el-row style="margin:15px 0" :gutter="10" type="flex">
            <el-col :span="14">
                <el-card class="box-card" shadow="hover">
                    <div slot="header" >
                        <span>项目登记</span>
                    </div>
                    <el-form ref="form" :model="form" label-width="100px">
                        <el-form-item label="项目编号">
                            <el-input v-model="form.num"></el-input>
                        </el-form-item>
                        <el-form-item label="项目名称">
                            <el-input v-model="form.name"></el-input>
                        </el-form-item>
                        <el-form-item label="项目类别">
                            <el-select
                                    style="width: 100%"
                                    v-model="form.type"
                                    filterable
                                    allow-create
                                    default-first-option
                                    placeholder="">
                                <el-option
                                        v-for="item in xmList"
                                        :key="item.label"
                                        :label="item.label"
                                        :value="item.label">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="评审开始时间 ">
                            <el-date-picker
                                    style="width: 100%"
                                    v-model="form.time"
                                    type="date"
                                    placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="评审地点">
                            <el-input v-model="form.address" type="textarea" :rows="4"></el-input>
                        </el-form-item>
                        <el-form-item label="联系人">
                            <el-input v-model="form.user"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="onSubmit" :loading="loading">提 交</el-button>
                            <el-button>取消</el-button>
                        </el-form-item>
                    </el-form>
                </el-card>
            </el-col>
            <el-col :span="10" class="el-card box-card is-hover-shadow">
                <div  class="el-card__header">
                    <span>项目附件</span>
                </div>
                <div class="el-card__body">
                    <el-upload
                            class="upload-demo"
                            action="/api/project/upload"
                            :on-change="handleChange"
                            list-type="picture"
                            :file-list="fileList">
                        <el-button size="small" type="primary">点击上传</el-button>
                    </el-upload>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            form: {
                name: '',
                region: '',
                date1: '',
                date2: '',
                delivery: false,
                type: [],
                resource: '',
                desc: ''
            },
            fileList:[],
            xmList:[]
        }
    },
    methods: {
        handleChange(file, fileList) {
            this.fileList = fileList.slice(-3);
        },
        onSubmit() {
            this.loading = true
            this.$http.post('/project/form', this.form)
            .then(resp => {
                this.$message[resp.data.type](resp.data.msg)
                if(resp.data.type=='success') this.$router.push('/main/project/list')
            }).catch(err=>{
                this.$message.error(err.message)
            }).finally(()=>{
                this.loading = false
            })
        },
        todo() {
            this.$message.info('稍后访问')
        },
        async getSelect() {
            try {
                const resp = await this.$http.get(`/project/select`)
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.xmList = resp.data
                }
            } catch (e) {
                this.$message.error(e.message)
            }
        }
    },
    mounted() {
        this.getSelect()
    },
}
</script>
